@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.form_section {
  max-width: 1200px;
  margin: 30px auto;
  padding: 20px;
font-family: 'Poppins', sans-serif;
}

td , th {
  max-width: 100px;
  min-width: 80px;
  height: 45px;
  border: 1px solid #6666666c !important;
  font-size: 14px;
  text-transform: capitalize;
  color: #222222;
}

td.bg {
  background: #ececec9f;
  color: #222222;
  font-weight: 500;
}


input[type="text"] , input[type="time"] , input[type="date"] {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #66666667;
  border-radius: 5px;
  color: #666666;
}

textarea {
  width: 100%;
  height: 175px;
  border: 1px solid #66666663;
  border-radius: 5px;
  padding: 10px;
}

.table_btns {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}


button {
  display: block;
  border: navajowhite;
  font-size: 14px;
  color: white;
  background: #666666;
  padding: 10px 20px;
  border-radius: 5px;
}

b {
  font-size: 16px;
  color: #222222;
}

.right{
  text-align: end;
}